import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastService } from '../../services/toast.service';
import { AnimationEvent, animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'error-toast',
  standalone: true,
  imports: [CommonModule],
  template: `
  <div
    *ngIf="toastModel().visible"
    [class]="'fixed top-20 right-20 left-20 lg:left-auto z-[5000] flex items-center p-15 rounded-10 shadow' + toastModel.position"
    [ngClass]="{
      'bg-orange-50': toastModel().type === 'warning',
      'bg-white': toastModel().type === 'info',
      'bg-red-50': toastModel().type === 'error'
    }"
    [@fadeAnimation]="{
      value: toastModel().animationState,
      params:{
        fadeIn: 300,
        fadeOut: 2500
      }
    }"
    (@fadeAnimation.done)="onFadeFinished($event)"
  >
    @if (toastModel().type !== 'info') {
      <div
        class="hidden lg:inline-flex items-center justify-center w-32 h-32 rounded-lg mr-15"
        [ngClass]="{
        'text-orange-500 bg-orange-100': toastModel().type === 'warning',
        'text-red-500 bg-red-100': toastModel().type === 'error'
        }"
      >
        <i 
          class="iconoir-xmark-circle-solid text-20"
          [ngClass]="{
            'iconoir-warning-circle-solid': toastModel().type === 'warning',
            'iconoir-xmark-circle-solid': toastModel().type === 'error'
          }"
        ></i>
      </div>
    }
    <div 
      class="flex flex-col"
      [ngClass]="{
        'text-orange-600': toastModel().type === 'warning',
        'text-red-600': toastModel().type === 'error'
      }"
    >
      <div 
        class="font-medium"
        [ngClass]="{
        'text-orange-600': toastModel().type === 'warning',
        'text-red-600': toastModel().type === 'error'
      }"
      >{{toastModel().title}}</div>
      <div
        [ngClass]="{
          'text-orange-600': toastModel().type === 'warning',
          'text-red-600': toastModel().type === 'error'
        }"
      >{{toastModel().message}}</div>
    </div>
  </div>
  `,
  animations: [
    trigger('fadeAnimation', [
        state('default', style({ opacity: 1 })),
        transition('void => *', [style({ opacity: 0 }), animate('{{ fadeIn }}ms')]),
        transition(
            'default => closing',
            animate('{{ fadeOut }}ms', style({ opacity: 0 })),
        ),
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastComponent {
  private readonly _toastService = inject(ToastService);
  private _intervalId!: number;

  toastModel = this._toastService.toastState;
 
  close(): void {
    this._toastService.close()
  }

  onFadeFinished(event: AnimationEvent) {    
    const { toState } = event;
    const isFadeOut = toState === 'closing';
    const itFinished = this.toastModel().animationState === 'closing';

    if (isFadeOut && itFinished) {
      this.close();
    }
  }

  ngOnDestroy() {
    clearTimeout(this._intervalId);
  }
}
