import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastService } from './toast/services/toast.service';
import { ToastPosition, ToastType } from './toast/models/toast.model';

export const errorHandlingInterceptor = (
  request: HttpRequest<any>,
  next: HttpHandlerFn,
): Observable<HttpEvent<any>> => {
  const toastService = inject(ToastService);

  return next(request).pipe(
    catchError((error) => {
      if (error instanceof HttpErrorResponse) {        
        switch (error.status) {
          case 401:
            toastService.show(
              'Unauthorized',
              'You are not authorized to access this resource',
              5,
              ToastType.Error,
              ToastPosition.TopRight
            );
            break;
          case 404:
            toastService.show(
              'Not Found',
              'The resource you are looking for does not exist',
              5,
              ToastType.Error,
              ToastPosition.TopRight
            );
            break;
          default:
            toastService.show(
              'Erreur',
              error.error.message || error.message,
              5,
              ToastType.Error,
              ToastPosition.TopRight
            );

            break;
        }
      }
      return throwError(() => new Error(error));
    }),
  );
};
