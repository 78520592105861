import { Injectable } from '@angular/core';
import { ToastModel, ToastType, ToastPosition, ToastAnimationState } from '../models/toast.model';
import { patchState, signalState } from '@ngrx/signals';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  toastState = signalState<ToastModel>(new ToastModel(false));

  public show(
    title: string,
    message: string,
    seconds: number = 5,
    type: ToastType = ToastType.Info,
    position: ToastPosition = ToastPosition.TopRight
  ) {
    if (seconds <= 0) {
      seconds = 5;
    }

    patchState(this.toastState, {
      title,
      message,
      type,
      position,
      visible: true,
    });

    setTimeout(
      () => {
        patchState(this.toastState, { animationState: ToastAnimationState.Closing });
      },
      seconds * 1000
    );
  }

  public close() {
    patchState(this.toastState, new ToastModel(false));
  }
}